import { MigrationHandler } from '../migration/migration-handler';
import { PageId, WidgetsId } from '../constants';
import { settingsClient, Scope } from '@wix/app-settings-client';
import {addBookCheckoutPage, addBookingsPage} from './pages-actions';
import { addBookingsPagesAsPanel, createBookCheckoutState } from './pages-panel-actions';
import {
  getAllBookingsPages, getBookingsDefId,
  getBookingsData,
  getComponentData,
  getPageData,
  getTPAMainSectionPageRef,
  getTPAMainSectionStructure,
  navigateToPage,
  updateComponentData,
  updateComponentStyle, updatePageData
} from './editor-sdk-actions';

enum SettingsKeys {
  BOOK_FLOW_ACTION_TEXT = 'BOOK_FLOW_ACTION_TEXT',
  NO_BOOK_FLOW_ACTION_TEXT = 'NO_BOOK_FLOW_ACTION_TEXT',
  DISPLAY_IMAGE = 'DISPLAY_IMAGE',
  DISPLAY_TAG_LINE = 'DISPLAY_TAG_LINE',
  DISPLAY_DIVIDER = 'DISPLAY_DIVIDER',
  DISPLAY_PRICE = 'DISPLAY_PRICE',
  DISPLAY_DURATION = 'DISPLAY_DURATION',
  DISPLAY_START_DATE = 'DISPLAY_START_DATE',
  DISPLAY_DAYS_OFFERED = 'DISPLAY_DAYS_OFFERED',
  DISPLAY_BUTTON = 'DISPLAY_BUTTON',
  DISPLAY_MORE_INFO_LABEL = 'DISPLAY_MORE_INFO_LABEL',
  OFFERING_MORE_INFO_LABEL_FONT = 'OFFERING_MORE_INFO_LABEL_FONT',
  OFFERING_MORE_INFO_LABEL_COLOR = 'OFFERING_MORE_INFO_LABEL_COLOR',
  DISPLAY_MULTI_OFFERINGS_TITLE = 'DISPLAY_MULTI_OFFERINGS_TITLE',
  DISPLAY_CATEGORIES = 'DISPLAY_CATEGORIES',
  DISPLAY_SERVICE_DIVIDER = 'DISPLAY_SERVICE_DIVIDER',
  TEXT_ALIGNMENT = 'TEXT_ALIGNMENT',
  IMAGE_RESIZE_OPTION = 'IMAGE_RESIZE_OPTION',
  IMAGE_POSITION_OPTION = 'IMAGE_POSITION_OPTION',
  IMAGE_SHAPE_OPTION = 'IMAGE_SHAPE_OPTION',
  PIN_LOCATION = 'PIN_LOCATION',
  IMAGE_AND_TEXT_POSITION = 'IMAGE_AND_TEXT_POSITION',
  IMAGE_AND_TEXT_RATIO = 'IMAGE_AND_TEXT_RATIO',
  IMAGE_AND_TEXT_RATIO_IS_FLIPED = 'IMAGE_AND_TEXT_RATIO_IS_FLIPED',
  SELECTED_RESOURCES = 'SELECTED_RESOURCES',
  OFFERING_NAME_FONT = 'OFFERING_NAME_FONT',
  OFFERING_NAME_COLOR = 'OFFERING_NAME_COLOR',
  OFFERING_TAGLINE_FONT = 'OFFERING_TAGLINE_FONT',
  OFFERING_TAGLINE_COLOR = 'OFFERING_TAGLINE_COLOR',
  OFFERING_DETAILS_FONT = 'OFFERING_DETAILS_FONT',
  OFFERING_DETAILS_COLOR = 'OFFERING_DETAILS_COLOR',
  BACKGROUND_COLOR = 'BACKGROUND_COLOR',
  BORDER_COLOR = 'BORDER_COLOR',
  IMAGE_BACKGROUND_COLOR = 'IMAGE_BACKGROUND_COLOR',
  DIVIDER_COLOR = 'DIVIDER_COLOR',
  SERVICE_DIVIDER_COLOR = 'SERVICE_DIVIDER_COLOR',
  BORDER_WIDTH = 'BORDER_WIDTH',
  STRIP_BORDER_WIDTH = 'STRIP_BORDER_WIDTH',
  DIVIDER_WIDTH = 'DIVIDER_WIDTH',
  SERVICE_DIVIDER_WIDTH = 'SERVICE_DIVIDER_WIDTH',
  BUTTON_STYLE = 'BUTTON_STYLE',
  BUTTON_TEXT_FONT = 'BUTTON_TEXT_FONT',
  BUTTON_TEXT_COLOR_FILL = 'BUTTON_TEXT_COLOR_FILL',
  BUTTON_TEXT_COLOR_HOLE = 'BUTTON_TEXT_COLOR_HOLE',
  BUTTON_BORDER_COLOR = 'BUTTON_BORDER_COLOR',
  BUTTON_BACKGROUND_COLOR = 'BUTTON_BACKGROUND_COLOR',
  BUTTON_CORNER_RADIUS = 'BUTTON_CORNER_RADIUS',
  BUTTON_BORDER_WIDTH = 'BUTTON_BORDER_WIDTH',
  MULTI_OFFERINGS_TITLE_TEXT = 'MULTI_OFFERINGS_TITLE_TEXT',
  OFFERING_LIST_LAYOUT = 'OFFERINGS_LIST_LAYOUT',
  CATEGORY_NAME_FONT = 'CATEGORY_NAME_FONT',
  CATEGORY_NAME_COLOR = 'CATEGORY_NAME_COLOR',
  CATEGORY_HOVER_TEXT_COLOR = 'CATEGORY_HOVER_TEXT_COLOR',
  CATEGORY_BACKGROUND_COLOR = 'CATEGORY_BACKGROUND_COLOR',
  CATEGORY_HOVER_BACKGROUND_COLOR = 'CATEGORY_HOVER_BACKGROUND_COLOR',
  CATEGORY_SELECTED_BORDER_COLOR = 'CATEGORY_SELECTED_BORDER_COLOR',
  CATEGORY_HOVER_BORDER_COLOR = 'CATEGORY_HOVER_BORDER_COLOR',
  CATEGORY_SELECTED_TEXT_COLOR = 'CATEGORY_SELECTED_TEXT_COLOR',
  CATEGORY_DIVIDER_COLOR = 'CATEGORY_DIVIDER_COLOR',
  CATEGORY_SELECTED_UNDERLINE_COLOR = 'CATEGORY_SELECTED_UNDERLINE_COLOR',
  CATEGORY_ALIGNMENT = 'CATEGORY_ALIGNMENT',
  CATEGORY_LAYOUT_OPTION = 'CATEGORY_LAYOUT_OPTION',
  MULTI_OFFERINGS_TITLE_ALIGNMENT = 'MULTI_OFFERINGS_TITLE_ALIGNMENT',
  MULTI_OFFERINGS_TITLE_COLOR = 'MULTI_OFFERINGS_TITLE_COLOR',
  MULTI_OFFERINGS_TITLE_FONT = 'MULTI_OFFERINGS_TITLE_FONT',
  FIT_CATEGORY_WIDTH = 'FIT_CATEGORY_WIDTH',
  MULTI_OFFERINGS_BACKGROUND_COLOR = 'MULTI_OFFERINGS_BACKGROUND_COLOR',
  SPACE_BETWEEN_OFFERINGS = 'SPACE_BETWEEN_OFFERINGS',
  STRIP_SPACE_BETWEEN_OFFERINGS = 'STRIP_SPACE_BETWEEN_OFFERINGS',
  LAYOUT_CARDS_PER_ROW = 'LAYOUT_CARDS_PER_ROW',
  CARDS_SPACING = 'CARDS_SPACING',
  STRIP_SIDE_PADDING = 'LAYOUT_SIDE_PADDING',
  GRID_SIDE_PADDING = 'INFO_SIDE_PADDING',
  CLASSIC_SIDE_PADDING = 'CLASSIC_SIDE_PADDING',
  OVERLAPPING_SIDE_PADDING = 'OVERLAPPING_SIDE_PADDING',
  STRIP_VERTICAL_PADDING = 'LAYOUT_VERTICAL_PADDING',
  GRID_VERTICAL_PADDING = 'INFO_VERTICAL_PADDING',
  CLASSIC_VERTICAL_PADDING = 'CLASSIC_VERTICAL_PADDING',
  OVERLAPPING_VERTICAL_PADDING = 'OVERLAPPING_VERTICAL_PADDING',
  PRESERVE_OLD_STYLES = 'PRESERVE_OLD_STYLES',
}

function openBar(sdk, appToken, translation, numberOfSteps) {
  return sdk.editor.openProgressBar(appToken, {
    title: translation.t('bookings-migration.progress-bar.title'),
    totalSteps: numberOfSteps,
  });
}

function setBarStep(sdk, appToken, translation, stepNumber) {
  return sdk.editor.updateProgressBar(appToken, {
    stepTitle: translation.t(`bookings-migration.progress-bar.step-${stepNumber}`),
    currentStep: stepNumber,
  });
}

function closeBar(sdk, appToken, isError = false) {
  return sdk.editor.closeProgressBar(appToken, {
    isError
  });
}

export async function handleMigration(sdk, appToken, translation, enableProgressBar = true) {
  if (enableProgressBar) {
    await openBar(sdk, appToken, translation, 3);
    await setBarStep(sdk, appToken, translation, 1);
  }
  const bookingsDefId = await getBookingsDefId(sdk);
  const colorsMap = await sdk.document.theme.colors.getAll();
  const migratory = new MigrationHandler(sdk, appToken, bookingsDefId);
  const schedulerSectionStructure = await getTPAMainSectionStructure(sdk, appToken, bookingsDefId);
  if (!schedulerSectionStructure) {
    // Bookings not installed, migration succeeded emptily.
    await closeBar(sdk, appToken, false);
    return Promise.resolve();
  }
  const schedulerSectionRef = await getTPAMainSectionPageRef(sdk, appToken, bookingsDefId);
  const schedulerData = await getPageData(sdk, appToken, schedulerSectionRef);
  const schedulerStyle = await getSchedulerStyles(schedulerSectionStructure.style, colorsMap);
  console.log('New Properties:');
  console.table(schedulerStyle);
  await migratory.migrateSection(schedulerSectionStructure, WidgetsId.BOOKINGS_LIST, PageId.BOOKINGS_LIST);
  try {
    enableProgressBar && await setBarStep(sdk, appToken, translation, 2);
    const bookCheckoutPage = await addBookCheckoutPage(sdk, bookingsDefId, appToken, schedulerSectionStructure.style);
    let bookCheckoutPageData = await getPageData(sdk, appToken, bookCheckoutPage.pageRef);
    schedulerData.pageBackgrounds.desktop.ref.id = bookCheckoutPageData.pageBackgrounds.desktop.ref.id;
    schedulerData.pageBackgrounds.mobile.ref.id = bookCheckoutPageData.pageBackgrounds.mobile.ref.id;
    await updatePageData(sdk, appToken, bookCheckoutPage.pageRef, {pageSecurity: {...schedulerData.pageSecurity}, pageBackgrounds: {...schedulerData.pageBackgrounds}});
  } catch {
    console.log('Checkout page already exists, skipping this');
  }
  await addBookingsPagesAsPanel(sdk, appToken);
  await createBookCheckoutState(sdk, appToken);
  const compId = schedulerSectionStructure.id;
  enableProgressBar && await setBarStep(sdk, appToken, translation, 3);
  return setBookingsListStyles(sdk, appToken, schedulerStyle, compId)
  .then(async () => {
    await navigateToBookingsList(sdk, appToken);
    console.log('Will Resolve Now');
    enableProgressBar && await closeBar(sdk, appToken, false);
    return Promise.resolve();
  })
  .catch(async e => {
    enableProgressBar && await closeBar(sdk, appToken, true);
    return Promise.reject('Failed when setting styles: ' + e.message);
  });
}

export async function handleBrokenNoPagesMigration(sdk, appToken) {
  console.log('No Bookings pages found, Installing Bookings Pages');
  await addBookingsPage(PageId.BOOKINGS_LIST,sdk,appToken);
  await addBookingsPage(PageId.BOOKINGS_CHECKOUT,sdk,appToken);
  await addBookingsPagesAsPanel(sdk, appToken);
}

export async function handleLightMigration(sdk, appToken) {
  await addBookingsPagesAsPanel(sdk, appToken);
}

export function shouldProposeMigration(editorSdk, isAdi, appToken, instance) {
  const isFromTemplate = !JSON.parse(atob(instance.split('.')[1])).metaSiteId;
  return !isAdi && !isFromTemplate;
}

async function navigateToBookingsList(sdk, appToken) {
  const bookingsPages = await getAllBookingsPages(sdk, appToken);
  const bookingsListPageRef = bookingsPages.find(page => page.tpaPageId === 'bookings_list');
  return navigateToPage(sdk, appToken, bookingsListPageRef.id);
}

type Font = {
  style: {bold: boolean, italic: boolean},
  family: string,
  preset: string,
  displayName: string
} | null;
type Color = string | undefined | Object;
type Layout = 'STRIP' | 'GRID' | 'CLASSIC';
type CategoryLayout = 'tabs' | 'tags' | 'dropdown';
type ImageType = 'square' | 'round' | 'none';

interface SchedulerStyles {
  [SettingsKeys.OFFERING_LIST_LAYOUT]: Layout;
  [SettingsKeys.CATEGORY_LAYOUT_OPTION]: CategoryLayout;
  [SettingsKeys.IMAGE_SHAPE_OPTION]: ImageType;
  [SettingsKeys.CATEGORY_NAME_COLOR]: Color;
  [SettingsKeys.CATEGORY_NAME_FONT]: Font;
  [SettingsKeys.CATEGORY_HOVER_TEXT_COLOR]: Color;
  [SettingsKeys.CATEGORY_BACKGROUND_COLOR]: Color;
  [SettingsKeys.CATEGORY_HOVER_BACKGROUND_COLOR]: Color;
  [SettingsKeys.CATEGORY_SELECTED_BORDER_COLOR]: Color;
  [SettingsKeys.CATEGORY_DIVIDER_COLOR]: Color;
  [SettingsKeys.CATEGORY_HOVER_BORDER_COLOR]: Color;
  [SettingsKeys.CATEGORY_SELECTED_TEXT_COLOR]: Color;
  [SettingsKeys.CATEGORY_SELECTED_UNDERLINE_COLOR]: Color;
  [SettingsKeys.OFFERING_NAME_COLOR]: Color;
  [SettingsKeys.OFFERING_NAME_FONT]: Font;
  [SettingsKeys.OFFERING_DETAILS_COLOR]: Color;
  [SettingsKeys.OFFERING_DETAILS_FONT]: Font;
  [SettingsKeys.OFFERING_TAGLINE_COLOR]: Color;
  [SettingsKeys.OFFERING_TAGLINE_FONT]: Font;
  [SettingsKeys.OFFERING_MORE_INFO_LABEL_FONT]: Font;
  [SettingsKeys.OFFERING_MORE_INFO_LABEL_COLOR]: Color;
  [SettingsKeys.BORDER_COLOR]: Color;
  [SettingsKeys.BACKGROUND_COLOR]: Color;
  [SettingsKeys.SERVICE_DIVIDER_COLOR]: Color;
  [SettingsKeys.MULTI_OFFERINGS_TITLE_COLOR]: Color;
  [SettingsKeys.MULTI_OFFERINGS_TITLE_FONT]: Font;
  [SettingsKeys.MULTI_OFFERINGS_BACKGROUND_COLOR]: Color;
  [SettingsKeys.BUTTON_TEXT_COLOR_FILL]: Color;
  [SettingsKeys.BUTTON_TEXT_FONT]: Font;
  [SettingsKeys.BUTTON_BACKGROUND_COLOR]: Color;
  [SettingsKeys.DISPLAY_CATEGORIES]: boolean;
  [SettingsKeys.DISPLAY_MULTI_OFFERINGS_TITLE]: boolean;
  [SettingsKeys.DISPLAY_IMAGE]: boolean;
  [SettingsKeys.DISPLAY_MORE_INFO_LABEL]: boolean;
  [SettingsKeys.MULTI_OFFERINGS_TITLE_TEXT]: string;
  [SettingsKeys.BOOK_FLOW_ACTION_TEXT]: string;
  [SettingsKeys.NO_BOOK_FLOW_ACTION_TEXT]: string;
  [SettingsKeys.TEXT_ALIGNMENT]: string;
  [SettingsKeys.IMAGE_RESIZE_OPTION]: string;
  [SettingsKeys.DISPLAY_DIVIDER]: boolean;
  [SettingsKeys.DISPLAY_TAG_LINE]: boolean;
  [SettingsKeys.DISPLAY_DAYS_OFFERED]: boolean;
  [SettingsKeys.PRESERVE_OLD_STYLES]: boolean;
}

export async function getSchedulerStyles(schedulerStyle, colorsMap): Promise<SchedulerStyles> {
  if (!schedulerStyle.style) {
    return {} as SchedulerStyles;
  }
  const properties = schedulerStyle.style.properties;
  const getValueOf = (property) => property && (JSON.parse(property).cssFontFamily || JSON.parse(property).value);
  const getLayoutName = (schedulerLayoutname) => {
    switch(schedulerLayoutname) {
      case 'angelina':
        return 'STRIP';
      case 'bar':
        return 'GRID';
      case 'heidi':
        return 'CLASSIC';
    }
  };
  const getImageType = (schedulerImageType, schedulerLayout) => {
    if (schedulerImageType === 'none' || (schedulerLayout === 'bar' && schedulerImageType !== 'round')) {
      return 'rectangle';
    }
    return schedulerImageType;
  };
  const mapColor = (color, alpha = 1) => {
    function hexToRgbA(hex){
      if (alpha === undefined) {
        alpha = 1;
      }
      let colorPart;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        colorPart= hex.substring(1).split('');
        if(colorPart.length== 3){
          colorPart= [colorPart[0], colorPart[0], colorPart[1], colorPart[1], colorPart[2], colorPart[2]];
        }
        colorPart= '0x'+colorPart.join('');
        return 'rgba('+[(colorPart>>16)&255, (colorPart>>8)&255, colorPart&255].join(',')+',' + alpha + ')';
      }
      throw new Error(`${color} is a bad Hex`);
    }
    if (color) {
      switch (color.charAt(0)) {
        case '#':
          return {value: hexToRgbA(color)};
        case 'r':
          return {value: color};
        case 'h':
          return {value: color};
        default:
          return {value: hexToRgbA(colorsMap[color]), themeName: color};
      }
    } else {
      return undefined;
    }
  };
  const mapFont = (font, size = 16) => {
    if (font) {
      const parsedFont = JSON.parse(font);
      return {
        size,
        style: {bold: false, italic: false},
        family: parsedFont.value,
        preset: 'Custom',
        displayName: 'Custom'
      }
    } else {
      return undefined;
    }
  };
  console.log('Old Properties:');
  console.table(properties);
  return {
    OFFERINGS_LIST_LAYOUT: getLayoutName(
      properties.param_font_servicesLayoutPreset ?
        getValueOf(properties.param_font_servicesLayoutPreset) :
        'bar'),
    IMAGE_SHAPE_OPTION: getImageType(
      properties.param_font_servicesLayoutImageType ?
        getValueOf(properties.param_font_servicesLayoutImageType) :
        'rectangle', getValueOf(properties.param_font_servicesLayoutPreset)),
    CATEGORY_NAME_COLOR: mapColor(properties.param_color_colorCategoryText),
    CATEGORY_NAME_FONT: mapFont(properties.param_font_fontCategoryText),
    CATEGORY_HOVER_TEXT_COLOR: mapColor(properties.param_color_colorCategoryText),
    CATEGORY_SELECTED_TEXT_COLOR: mapColor(properties.param_color_colorCategoryTextSelected),
    CATEGORY_SELECTED_UNDERLINE_COLOR: mapColor(properties.param_color_colorCategoryTextSelected, 0),
    CATEGORY_DIVIDER_COLOR: mapColor(properties.param_color_colorCategoryText, 0.2),
    CATEGORY_HOVER_BORDER_COLOR: mapColor(properties.param_color_colorCategoryText, 0.6),
    CATEGORY_SELECTED_BORDER_COLOR: mapColor(properties.param_color_colorCategoryTextSelected),
    CATEGORY_BACKGROUND_COLOR: mapColor('color_11', 0),
    CATEGORY_HOVER_BACKGROUND_COLOR: mapColor('color_11', 0),
    OFFERING_NAME_COLOR: mapColor(properties.param_color_colorTitle),
    OFFERING_NAME_FONT: mapFont(properties.param_font_fontTitle, 22),
    OFFERING_DETAILS_COLOR: mapColor(properties.param_color_colorIndividualText),
    OFFERING_DETAILS_FONT: mapFont(properties.param_font_fontIndividualText, 15),
    OFFERING_TAGLINE_COLOR: mapColor(properties.param_color_colorIndividualText),
    OFFERING_TAGLINE_FONT: mapFont(properties.param_font_fontIndividualText, 15),
    OFFERING_MORE_INFO_LABEL_COLOR: mapColor(properties.param_color_colorIndividualText),
    OFFERING_MORE_INFO_LABEL_FONT: mapFont(properties.param_font_fontIndividualText, 15),
    BORDER_COLOR: mapColor(properties.param_color_colorIndividualCardBorder, properties['alpha-param_color_colorIndividualCardBorder']) || mapColor('color_12'),
    BACKGROUND_COLOR: getValueOf(properties.param_font_servicesLayoutPreset) !== 'angelina' ? mapColor(properties.param_color_colorCardBackground, properties['alpha-param_color_colorCardBackground']) : mapColor('#ffffff', 0),
    MULTI_OFFERINGS_TITLE_COLOR: mapColor(properties.param_color_colorPageTitle),
    MULTI_OFFERINGS_TITLE_FONT: mapFont(properties.param_font_fontPageTitle, 26),
    MULTI_OFFERINGS_BACKGROUND_COLOR: mapColor(properties.param_color_colorPageBackground, properties['alpha-param_color_colorPageBackground']) || mapColor('#ffffff', 0),
    BUTTON_TEXT_COLOR_FILL: mapColor(properties.param_color_colorPrimaryButtonText),
    BUTTON_TEXT_FONT: mapFont(properties.param_font_fontButtonText),
    BUTTON_BACKGROUND_COLOR: mapColor(properties.param_color_colorButton),
    DISPLAY_CATEGORIES: getValueOf(properties.param_font_servicesLayoutCategoryPosition) !== 'none',
    DISPLAY_MULTI_OFFERINGS_TITLE: getValueOf(properties.param_font_textPageTitle_Checkbox) !== 'false',
    DISPLAY_IMAGE: getValueOf(properties.param_font_servicesLayoutImageType) !== 'none',
    MULTI_OFFERINGS_TITLE_TEXT: getValueOf(properties.param_font_textPageTitle),
    BOOK_FLOW_ACTION_TEXT: getValueOf(properties.param_font_textBookButton),
    NO_BOOK_FLOW_ACTION_TEXT: getValueOf(properties.param_font_textMoreInfoButton),
    SERVICE_DIVIDER_COLOR: mapColor(properties.param_color_colorIndividualListBorder, properties['alpha-param_color_colorIndividualListBorder']),
    TEXT_ALIGNMENT: 'center',
    IMAGE_RESIZE_OPTION: 'crop',
    CATEGORY_LAYOUT_OPTION: 'tags',
    DISPLAY_DIVIDER: false,
    DISPLAY_TAG_LINE: true,
    DISPLAY_DAYS_OFFERED: false,
    DISPLAY_MORE_INFO_LABEL: true,
    PRESERVE_OLD_STYLES: true,
  }
}

export async function setBookingsListStyles(sdk, appToken, newStyles, compId) {
  const { instanceId, instance } = await getBookingsData(sdk, appToken);
  const resetStyles = () => updateComponentStyle(sdk, appToken, compId, {});
  const setExternalId = async function (appToken, compPointer, referenceId) {
    await updateComponentData(sdk, appToken, compPointer, {referenceId});
  };
  const getExternalId = async function (appToken, compPointer) {
    const { referenceId } = await getComponentData(sdk, appToken, compPointer);
    return referenceId;
  };
  const bookingsDefId = await getBookingsDefId(sdk);
  await resetStyles();
  const appSettings = settingsClient({
    scope: Scope.COMPONENT,
    apiUrl: '//editor.wix.com/_api/app-settings-service',
    adapter: {
      getAppDefId: () => bookingsDefId,
      getInstanceId: () => instanceId,
      getExternalId: () => getExternalId(appToken, {type: "DESKTOP", id: compId}),
      setExternalId: (id) => {
        console.log(`Write settings for instanceId: ${instanceId} externalId: ${id}`);
        return setExternalId(appToken, {type: "DESKTOP", id: compId} , id)
      },
      triggerSettingsUpdated: (data, scope) => {},
      onSettingsUpdated: (scope, cb) => {},
      signedInstance: instance
    }
  });
  const relevantKeys = Object.keys(newStyles).filter(key => typeof newStyles[key] !== 'undefined');
  return Promise.all(relevantKeys.map(relevantKey => appSettings.set(relevantKey, newStyles[relevantKey])));
}
