import { DAILY_TIMETABLE_WIDGET_ID, BOOK_BUTTON_WIDGET_ID, STAFF_LIST_WIDGET_ID } from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';

export const MEMBERS_AREA_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const BOOKINGS_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
export const MY_WALLET_DEF_ID = '4aebd0cb-fbdb-4da7-b5d1-d05660a30172';

export enum WidgetsId {
  BOOKINGS_LIST = '621bc837-5943-4c76-a7ce-a0e38185301f',
}

export enum PageId {
  BOOKINGS_LIST = 'bookings_list',
  BOOKINGS_CHECKOUT = 'book_checkout',
  SCHEDULER = 'scheduler',
  BOOKINGS_MEMBERS_AREA = 'bookings_member_area',
  MY_WALLET = 'my_wallet',
  BOOKINGS_SERVICE_PAGE = 'Booking Service Page',
}

export const experiments =  {
  TRANSLATE_APP_BUILDER_WIDGETS_CONTENT: 'specs.bookingsViewerScript.TranslateAppBuilderWidgetContent',
  LINKED_STYLES: 'specs.bookingsViewerScript.LinkedStyles',
  BOOKABLE_TIME_TABLE: 'specs.bookingsViewerScript.BookableDailyTimeTable'
};

export const PETRI_BASE_URL = 'https://www.wix.com';
export const PETRI_SCOPE = 'bookings-viewer-script';
export const DAILY_TIMETABLE_WIDGET_CONTROLLER_ID = DAILY_TIMETABLE_WIDGET_ID;
export const BOOK_BUTTON_WIDGET_CONTROLLER_ID = BOOK_BUTTON_WIDGET_ID;
export const STAFF_LIST_WIDGET_CONTROLLER_ID = STAFF_LIST_WIDGET_ID;
